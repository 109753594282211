import { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useNotificationQueue } from "context/NotificationQueue";
import {
    CheckoutDataProvider,
    useCheckoutData,
} from "checkout/context/CheckoutData";
import CheckoutComplete from "checkout/components/CheckoutComplete";
import SearchByInvoice from "checkout/components/SearchByInvoice";
import CartAndForm from "checkout/components/CartAndForm";
import ErrorMessage from "components/ErrorMessage";
import Loading from "components/Loading";
import Anchor from "components/Anchor";
import { NotificationType } from "components/Notification";
import Section from "components/Section";
import * as S from "./styles";

interface CheckoutProps {
    queryParams: CheckoutQueryParams;
}

const Checkout = ({ queryParams }: CheckoutProps) => {
    const { entityId, itemId } = useParams();
    const [, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (!itemId) return;

        // Remove invoiceId and invoiceAmount if checking out item, not invoice
        setSearchParams((params) => {
            params.delete(`invoiceId`);
            params.delete(`invoiceAmount`);
            return params;
        });
    }, [itemId, setSearchParams]);

    return (
        <CheckoutDataProvider
            entityId={entityId}
            itemId={itemId}
            queryParams={queryParams}
        >
            <RouteController entityId={entityId} itemId={itemId} />
        </CheckoutDataProvider>
    );
};

interface RouteControllerProps {
    entityId?: string;
    itemId?: string;
}

const RouteController = ({ entityId, itemId }: RouteControllerProps) => {
    const { addNotification } = useNotificationQueue();
    const {
        checkoutComplete,
        mainItem,
        entity,
        fetchLoading,
        fetchError,
        fetchSuccess,
        items,
        invalidSubscriptionId,
        invalidCustomerId,
        isSearchByInvoice,
    } = useCheckoutData();

    useEffect(() => {
        if (!fetchError) return;
        addNotification({ msg: fetchError, type: NotificationType.ERROR });
    }, [fetchError, addNotification]);

    if (fetchLoading) return <Loading />;
    if (checkoutComplete)
        return <CheckoutComplete completeData={checkoutComplete} />;
    if (invalidSubscriptionId || invalidCustomerId) {
        const entityName = entity?.name || `the link provider`;
        const entityContact = entity?.websiteUrl ? (
            <Anchor href={entity.websiteUrl}>{entityName}</Anchor>
        ) : (
            entityName
        );

        const errorType = invalidSubscriptionId
            ? `Invalid Stripe Subscription`
            : `Invalid Stripe Customer`;

        const errorMessage = invalidSubscriptionId ? (
            <>
                The subscription is either inactive or does not exist in Stripe.
                <br />
                Please double check the ID in Stripe. Contact {
                    entityContact
                }{" "}
                for help.
            </>
        ) : (
            <>
                The customer has either been deleted or does not exist in
                Stripe.
                <br />
                Please double check the ID in Stripe. Contact {
                    entityContact
                }{" "}
                for help.
            </>
        );
        return (
            <S.ErrorWrapper>
                <ErrorMessage msg={errorType}>{errorMessage}</ErrorMessage>
            </S.ErrorWrapper>
        );
    }

    if (isSearchByInvoice) return <SearchByInvoice />;
    if (fetchSuccess && entityId && (itemId || mainItem))
        return <CartAndForm />;
    return (
        <S.ErrorWrapper>
            <ErrorMessage msg="Something went wrong">
                {!entity ? (
                    <>
                        An error occurred while loading the page. Please reload
                        to try again.
                        <br />
                        If the problem persists, contact us on telegram at{" "}
                        <Anchor href={import.meta.env.VITE_LOOP_TELEGRAM}>
                            {import.meta.env.VITE_TELEGRAM_HELP}
                        </Anchor>{" "}
                        or email us at{" "}
                        <Anchor href={import.meta.env.VITE_EMAIL_HELP}>
                            {import.meta.env.VITE_EMAIL_HELP}
                        </Anchor>
                        .
                    </>
                ) : items && !mainItem ? (
                    <>
                        Item ${itemId} was not found.
                        <br />
                        Please contact us on telegram at{" "}
                        <Anchor href={import.meta.env.VITE_LOOP_TELEGRAM}>
                            {import.meta.env.VITE_TELEGRAM_HELP}
                        </Anchor>{" "}
                        or email us at{" "}
                        <Anchor href={import.meta.env.VITE_EMAIL_HELP}>
                            ${import.meta.env.VITE_EMAIL_HELP}
                        </Anchor>
                        for assistance.
                    </>
                ) : (
                    <>
                        An error occurred while loading the page. Please reload
                        to try again.
                        <br />
                        If the problem persists, contact us on telegram at{" "}
                        <Anchor href={import.meta.env.VITE_LOOP_TELEGRAM}>
                            {import.meta.env.VITE_TELEGRAM_HELP}
                        </Anchor>{" "}
                        or email us at{" "}
                        <Anchor href={import.meta.env.VITE_EMAIL_HELP}>
                            {import.meta.env.VITE_EMAIL_HELP}
                        </Anchor>
                        .
                    </>
                )}
            </ErrorMessage>
        </S.ErrorWrapper>
    );
};

export default Checkout;
